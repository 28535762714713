import { useQuery } from '@tanstack/vue-query';
import { userService } from '~/utils/api/userService';
import * as Sentry from '@sentry/vue';

export function useUser() {
  const { token } = useToken();
  const { data } = useQuery({
    queryKey: ['user'],
    queryFn: userService.getAccount,
    enabled: !!token.value,
  });

  watch(
    data,
    () => {
      if (data.value) {
        Sentry.setUser({
          email: data.value.name,
        });
      }
    },
    { immediate: true },
  );

  return { data };
}
